import { inject, Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

export enum Icons {
  AIRPLANE = 'airplane',
  ARROW_LEFT = 'arrow-left',
  BELL = 'bell',
  CHECK = 'check',
  CALENDAR = 'calendar',
  CHECK_CIRCLE = 'check-circle',
  CHEVRON_DOWN = 'chevron-down',
  CHEVRON_LEFT = 'chevron-left',
  CHEVRON_RIGHT = 'chevron-right',
  CLOCK = 'clock',
  CLOSE = 'close',
  DELETE_WITH_BACKGROUND = 'delete-with-background',
  EDIT = 'edit',
  ERROR = 'error',
  HOME = 'home',
  INFO_THICK = 'info-thick',
  INFO_THIN = 'info-thin',
  LEAF = 'leaf',
  LEAF_FILLED = 'leaf-filled',
  LOGOUT = 'logout',
  MAIL = 'mail',
  MAP_PIN = 'map-pin',
  MENU = 'menu',
  MINUS = 'minus',
  MINUS_IN_CIRCLE = 'minus-in-circle',
  PHONE = 'phone',
  PIGGY_BANK = 'piggy-bank',
  PLUS = 'plus',
  PLUS_IN_CIRCLE = 'plus-in-circle',
  REWARD = 'reward',
  TREE = 'tree',
  USER = 'user',
  SUCCESS = 'success',
  WARNING = 'warning',
  X = 'x',
}

@Injectable({
  providedIn: 'root',
})
export class SharedFeatureIconsService {
  private readonly matIconRegistry = inject(MatIconRegistry);
  private readonly domSanitizer = inject(DomSanitizer);

  registerIcons(): void {
    this.loadIcons(Object.values(Icons), 'assets/icons');
  }

  private loadIcons(iconKeys: Icons[], iconUrl: string): void {
    iconKeys.forEach((key) => {
      this.matIconRegistry.addSvgIcon(key, this.domSanitizer.bypassSecurityTrustResourceUrl(`${iconUrl}/${key}.svg`));
    });
  }
}
